.listElement {
  border: #386123 1px solid;
  margin: 2px auto;
  padding: 5px;
  background: #ffffff;
  text-align: center;
  border-radius: 6px;
  max-width: 200px;
  width: 80%;
  overflow: hidden;
}

.listElement .menu {
  /*float: right; not using this for now*/
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
}

.listElement .menu:hover .menu-items {
  display: block;
  padding: 8px;
  list-style-type: none;
  background: #ffffff;
  float: left;
}

.listElement .menu-items {
  display: none;
  position: absolute;
  border: #386123 1px solid;
  top: 0;
  right: 0;
  z-index: 1;
}

.listElement .compListTab {
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.listElement .centeredDivs {
  margin: auto;
  width: 100%;
  text-align: center;
}

.listElement input {
  width: 55px;
  text-align: center;
  margin: auto;
}

.listElement .menuAdjust {
  margin-left: 55px;
}

.listElement button {
  margin: 0px;
}
