.fabricSelect {
  margin: 10px;
  width: auto;
}

.fabricSelectList {
  display: grid;
  grid-template-columns: auto auto auto auto;
  background-color: white;
  margin: 20px 10%;
}

@media screen and (max-width: 600px) {
  .fabricSelectList {
    grid-template-columns: auto auto;
  }
}

.picker {
  margin: 10px auto;
}

.fabricSelect .swatch {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  cursor: pointer;
  margin: auto;
}

.fabricSelect .popover {
  border-radius: 6px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  height: 100px;
  width: 100px;
}

.fabricIdBlock {
  display: flex;
  margin: auto;
}
