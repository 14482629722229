.quiltCalcForm {
  display: grid;
  grid-auto-flow: row;
  min-height: 100vh;
}

.quiltCalcForm .formSubmit {
  margin: 10px;
}

.quiltCalcForm .fabricEstGrid {
  text-align: center;
  align-content: center;
  padding: 10px;
  background-color: white;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}



.quiltCalcForm .cutRecsWrapper {
  display: inline-block;
  width: 100%;
  min-height: 50px;
}

.littleCalcCutWrapper{
  width: 99%;
  margin: auto;
}

.quiltCalcForm .cutRecs {
  display: block;
  margin: auto;
  max-height: 420px;
  min-height: 50px;
}

.quiltCalcForm .writtenCutRecommendations {
  list-style-position: inside;
}

.quiltCalcForm .formError {
  color: #cc0000;
  text-align: center;
}

.quiltCalcForm input {
  width: 80px;
  margin: 5px auto;
}

.quiltCalcForm label {
  font-weight: 100;
  color: #26282c;
  margin-top: 10px;
}

.quiltCalcForm select {
  width: auto;
  min-width: 150px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #a4bd97;
  color: #26282c;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 100;
  font-size: 18px;
  margin: 5px auto;
  display: block;
}

.quiltCalcForm .overrideMarginAuto {
  margin: auto;
}

.quiltCalcForm .fabCutRecs {
  margin: 10px;
}

@media screen and (min-width: 600px) {
  .quiltCalcForm #blockRepeatInput {
    margin: auto;
  }

  .quiltCalcForm select {
    margin: 5px 10px;
  }
  .littleCalcCutWrapper{
    width: 60%;
  }

  .quiltCalcForm .fabCutRecs {
    padding: 5px 0px 5px 0px;
    display: inline-grid;
    grid-template-columns: auto auto auto;
    border: 1px solid #a4bd97;
    padding-top: 3px;
  }

  .fabCutRecs .inline-h3 {
    grid-column: 1/ span 3;
    width: 100%;
    padding-bottom: 10px;
  }

  .quiltCalcForm .writtenCutRecommendations {
    grid-column: 1/1;

    ul{
      padding: 0;
    }
  }

  .quiltCalcForm .cutRecsWrapper {
    grid-column: 2/ span 2;
  }
}

.wofCutInstructions:hover {
  border: 1px solid #a4bd97;
  border-radius: 3%;
  padding-top: 3px;
}
