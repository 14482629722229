.adelineHelperForm input {
  width: fit-content;
  padding: 10px;
  max-width: 100%;
  margin: 10px auto;
}

.adelineHelperForm .letterPreviewDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px;
}

.adelineHelperForm label {
  background-color: #ffffff;
  color: #000000;
  text-align: center;
}

.adelineHelperForm input[type="checkbox"] {
  margin-right: 5px;
  display: inline;
}

.adelineHelperForm .narrowColumn {
  padding: 10px 30px;
  text-align: center;
}

.adelineHelperForm img {
  max-height: 500px;
  max-width: 100%;
}
