.blockComp {
  font-family: sans-serif;
  text-align: center;
  border: 1px solid #386123;
  display: grid;
  align-content: center;
  margin: 20px;
  background-color: white;
  font-family: "Playfair Display";
}

.blockCompError {
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  display: inline-flex;
  padding: 5px;
}

.blockComp-first-row {
  grid-row-start: 1;
  grid-column: 1 / span 5;
  display: flex;
  justify-content: space-between; /* This will align items to both ends */
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .blockComp {
    grid-template-columns: auto auto;
    padding: 20px 5%;
  }
  .blockCompEx {
    grid-row-start: 2;
    grid-column: 1 / span 4;
    padding: 0px 10%;
  }

  .blockComp-second-row {
    grid-row-start: 3;
    grid-column: 1 / span 4;
    padding: 0px 10%;
  }

  .blockComp-third-row {
    grid-row-start: 4;
    grid-column: 1 / span 4;
    padding: 0px 10%;
  }
}

@media screen and (min-width: 601px) {
  .blockComp {
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto;
    grid-auto-flow: row;
    padding: 20px;
    margin: 20px 10%;
  }
  .snowballInput .blockComp input {
    margin: auto;
  }
  .blockCompSettings {
    margin: 3px 10px;
  }
  .blockCompEx {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 4;
    display: inline-grid;
    justify-content: center;
    padding: 10px;
  }
  .blockCompCustomSettings {
    grid-row-start: 3;
  }
  .blockComp-second-row {
    grid-row-start: 2;
    grid-column: 2 / span 4;
    display: inline-flex;
    justify-content: space-evenly;
  }
  .blockComp-third-row {
    grid-row-start: 3;
    grid-column: 2 / span 4;
    display: inline-flex;
    justify-content: space-evenly;
  }
}
