/*List elements on the blog page*/
.post-listing {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin: 10px 20px;
}

.post-listing img {
  width: 200px;
  height: auto;
  margin-right: 20px; /* Adds some space between the image and the text content */
}

.post-listing .text-content {
  display: flex;
  flex-direction: column; /* Stacks the h2, excerpt, and date from top to bottom */
  width: 100%;
}

.post-listing h2,
.post-listing .excerpt,
.post-listing .date {
  text-align: left; /* Removes default align */
}

.post-listing .excerpt,
.post-listing .date {
  color: #26282c;
}

/* Further styling for responsiveness and aesthetics */
@media (max-width: 768px) {
  .post-listing {
    flex-direction: column; /* Stacks image and text on top of each other on small screens */
  }

  .post-listing img {
    margin-right: 0; /* Removes right margin on small screens */
    margin-bottom: 10px; /* Adds space between the image and text */
    width: 100%; /* Full width of the container */
  }
}

/*Blog posts themselves*/
.blogPostPage,
.blogPostPage p {
  line-height: 25px;
  text-align: left;
  font-size: 20px;
}

.blogPostPage h2 {
  font-size: 25px;
  font-weight: 500;
}

.blogPostPage h3 {
  font-size: 22px;
  font-weight: 300;
}

.author {
  display: block;
  font-family: "Playfair Display";
  font-weight: 100;
  font-size: 16px;
}

.pubDate {
  font-size: 16px;
}

.blogPostPage img,
.exampleFeaturedMedia {
  max-height: 500px;
  max-width: 100%;
  width: auto;
  margin: auto;
  display: block;
}

.aboutItemGrid {
  font-family: "Playfair Display";
  text-align: left;
  border: 3px;
  display: grid;
  align-content: center;
  grid-template-columns: 50% 50%;
  margin: auto;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .aboutItemGrid {
    grid-template-columns: 100%;
  }
}

@media screen and (min-width: 600px) {
  .aboutItemMedia {
    width: 50%;
  }
}

.aboutItemMedia {
  margin: auto;
  display: block;
  width: 80%;
}

.aboutItemText {
  padding: 10px;
}
