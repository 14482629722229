body {
  background-color: #b9cec8;
  margin: 0;
  font-family: "Playfair Display";
  color: #26282c;
}

.root{
  min-height: 100vh;
}

.basicBackground {
  background: white;
  margin: 20px 5%;
  padding: 20px 5px;
}

.formBackground {
  background: white;
  margin: 20px 0;
  padding: 20px 10px;
}

p {
  text-align: center;
}

.MuiAppBar-root {
  background-color: white;
}

.MuiAppBar-root h1{
  align-self: center;
}

.footer {
  font-family: sans-serif;
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 5px 0px #386123;
  left: 0;
  bottom: 0;
  margin-top: auto;
}

.footer-item {
  font-family: "Playfair Display";
  font-size: 14px;
  padding: 10px;
  display: inline-grid;
  max-height: 56px;
  margin: auto;
}

h1 {
  font-family: "Playfair Display";
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: #386123;
}

h2 {
  font-family: "Playfair Display";
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

h3 {
  font-family: "Playfair Display";
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

a:link {
  text-decoration: none;
  color: #00008b;
}

a:visited {
  text-decoration: none;
  color: #00008b;
}

a:hover {
  text-decoration: none;
  color: #6082b6;
}

a:active {
  text-decoration: none;
}
.inline-h2 {
  font-size: 32px;
}

.inline-h3 {
  font-size: 20px;
}

button {
  background-color: #386123;
  color: #ffffff;
  border: none;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px 10px;
  font-family: "Playfair Display";
  margin: 10px auto;
  display: block;
}

button:hover {
  background-color: rgb(242, 254, 217);
  color: #386123;
}

button.disabled {
  background-color: rgb(77, 89, 92);
  color: black;
}

label {
  width: fit-content;
  min-height: 30px;
  text-align: center;
  font-size: 18px;
  border-radius: 6px;
  display: block;
  margin: auto;
}

input {
  border-radius: 2px;
  border: 1px solid #a4bd97;
  color: #386123;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  display: block;
  margin: auto;
}

.formError {
  color: #cc0000;
}

.softFormError {
  color: #ff9900;
}

.MuiAccordionSummary-content {
  justify-content: center;
  color: #386123;
  font-weight: 400;
  color: #00008b;
}

.MuiAccordion-root {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
}

.accordian-image {
  margin: auto;
  padding: 10px;
  max-width: 100%;
  display: block;
}

@media screen and (min-width: 600px) {
  .accordian-image{
    max-width: 1000px;
  }
}
