.App {
  font-family: sans-serif;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .grid {
    display: grid;
    grid-auto-columns: 30% 40% 30%;
  }

  .grid-item {
    display: inline-grid;
    background: #ffffff;
    padding: 5px;
    box-shadow: 2px 2px 5px 0px #386123;
  }

  .leftColumn {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .midColumn {
    grid-column-start: 2;
    grid-row-start: 1;
  }

  .rightColumn {
    grid-column-start: 3;
    grid-row-start: 1;
  }
}

@media screen and (max-width: 600px) {
  .grid {
    display: grid;
    grid-auto-columns: 100%;
    row-gap: 10px;
  }

  .grid-item {
    display: inline-grid;
    background: #ffffff;
    padding: 5px;
    box-shadow: 2px 2px 5px 0px #386123;
  }

  .leftColumn {
    grid-row-start: 1;
  }

  .midColumn {
    grid-row-start: 2;
  }

  .rightColumn {
    grid-row-start: 3;
  }
}

.compList {
  height: 300px;
  overflow: scroll;
  padding: 5px;
  overflow-x: hidden;
  border-bottom: #000000 solid 1px;
}

/* CompList Scrollbar */
.compList::-webkit-scrollbar {
  width: 5px;
}
.compList::-webkit-scrollbar-thumb {
  background: #386123;
  border-radius: 2px;
}

.quiltDesign {
  border: 1px solid #000000;
  margin: auto;
}

.contentSVG {
  fill: #ffffff;
  stroke-width: 0.5px;
  stroke: #000000;
}

.quiltBlock {
  border: 0.5px solid #000000;
  position: relative;
  display: inline-block;
  background: #ffffff;
}

.compListTabs {
  border-bottom: #000000 solid 1px;
}

.compListTabs div:hover {
  background-color: rgb(242, 254, 217);
  color: #386123;
}

.compListTabs-right {
  float: right;
  margin: 10px 15% 10px 0px;
  cursor: pointer;
}

.compListTabs-left {
  float: left;
  margin: 10px 0px 10px 15%;
  cursor: pointer;
}

.addItemSettings {
  padding: 10px;
}

.addItemSettings input {
  max-width: 45px;
  margin: 0px 5px;
}

.addItemSettings select {
  max-width: 100px;
  margin: 0px 5px;
}

.submitButtonContainer {
  position: relative;
  height: 40px;
  padding-top: 10px;
}

.submitButton {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 75%;
}

.menu {
  height: 50px;
  width: 50px;
}
