.colorPaletteForm canvas {
  max-width: 100%;
  max-height: 400px;
  margin: 20px auto;
}

.colorPaletteForm .grid-2 {
  display: grid;
  grid-auto-flow: row;
  column-gap: 10px;
  row-gap: 10px;
  justify-items: center;
  margin: auto;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  justify-items: center;
}
@media screen and (min-width: 600px) {
  .colorPaletteForm .grid-2 {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
        0,
        1fr
      );
  }
}

.colorPaletteForm .swatch {
  height: 50px;
  width: 100%;
}

.colorPaletteForm .exampleImage {
  max-width: 100%;
  margin: auto;
  min-height: 70px;
}

.colorPaletteForm label {
  width: fit-content;
  min-height: 30px;
  text-align: center;
  margin: 5px auto;
  background-color: #386123;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.colorPaletteForm input[type="file"] {
  display: none;
}
