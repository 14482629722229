.description {
  text-align: center;
  margin: 10px 10%;
  font-family: "Playfair Display";
  font-weight: 100;
  font-size: 18px;
}

.homeGrid {
  font-family: "Playfair Display";
  text-align: center;
  border: 3px;
  display: grid;
  align-content: center;
  grid-template-columns: auto;
  margin: 20px 10%;
  padding: 10px;
  background-color: white;
  box-shadow: 2px 2px 5px 0px #386123;
}
.homeGrid-item {
  font-family: "Playfair Display";
  font-size: 18px;
  display: inline-grid;
  padding: 10px 0px;
}

.homeGrid-image-item {
  display: inline-grid;
  width: 75%;
  margin: auto;
  padding: 10px 0px;
  min-height: 170px;
}

h2 {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .homeGrid-full-line-item {
    font-family: "Playfair Display";
    font-size: 18px;
    padding: 10px;
    display: inline-grid;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .homeGrid-image-item {
    display: inline-grid;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 6;
    width: 100%;
    min-height: 170px;
  }
  .homeGrid {
    grid-template-columns: auto auto;
  }
}

hr {
  border: 0px;
}

iframe {
  margin: auto;
  display: block;
  width: 100%;
}

.notation {
  text-align: center;
  font-size: 14px;
  margin: 0px 15px;
  padding: 0;
}
